<template>
  <div class="">
    <div
      class="p-1 m-1"
      style="background-color: white; border-radius: 3px;"
    >
      <b-row>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0"
        >
          <label class="saj-text">{{ $t("Subsidiary") }}:</label>
          <b-form-select
            v-model="selectedSub"
            size="md"
            class="saj-text"
            :options="subsidiaryList"
            @change="search = false"
          />
        </b-col>

        <b-col
          cols="2"
          class="mt-2"
        >
          <b-button
            variant="primary"
            class=""
            block
            style="margin-top: 3px"
            @click="getCore(), search = true"
          >

            <span class="saj-button d-md-none d-lg-block"> {{ $t("Search") }} </span>
          </b-button>
        </b-col>
        <b-col
          cols="2"
          class="mt-2"
        >
          <b-button
            variant="primary"
            class=""
            block
            style="margin-top: 3px"
            @click="clearSearch()"
          >
            <span class="saj-button d-md-none d-lg-block"> {{ $t("Clear") }}</span>
          </b-button>
        </b-col>
      </b-row>
    </div>

    <div
      v-if="selectedSub !== null && search"
      class="container"
    >
      <b-tabs
        card
      >
        <b-tab
          class="p-0 saj-title"
          :title="$t('Core Competencies')"
        >
          <b-card
            no-body
            class="mt-1 p-1"
            style="height: 100%; overflow-x: hidden;"
          >
            <div>
              <b-tabs>
                <b-tab
                  v-for="(a, idx) in coreList"
                  :key="idx"
                  :title="isEnglish ? a.core_bi : a.core_bm"
                >
                  <competency
                    :key="refreshTable"
                    :core-id="a.id"
                    @emit-subcore-data="val => subInfo = val"
                    @pass-id="val => coreId = val"
                    @pass-subcore-id="val => subcoreId = val"
                  />
                </b-tab>
              </b-tabs>
            </div>
          </b-card>
        </b-tab>
        <b-tab
          class="p-0 saj-title"
          :title="$t('Behavioural Competencies')"
        >
          <b-card
            no-body
            class="mt-1 p-1"
            style="height: 100%; overflow-x: hidden; min-height: 370px;"
          >
            <div>
              <behavioural
                :subsidiary-id="selectedSub"
              />
              <!-- <behavioural
                :subsidiary-id="selectedSub"
              /> -->
            </div>
          </b-card>
        </b-tab>
      </b-tabs>
      <!-- Modal -->
      <b-modal
        id="new-subcore"
        :hide-header="true"
        :hide-footer="true"
        :centered="true"
        size="lg"
      >
        <subcore
          :is-update="false"
          :core-id="coreId"
          @close="$bvModal.hide('new-subcore')"
          @reload-subcore="reload()"
        />
      </b-modal>
      <b-modal
        id="edit-subcore"
        :hide-footer="true"
        :hide-header="true"
        :centered="true"
        size="lg"
      >
        <subcore
          :core-data="subInfo"
          @close="$bvModal.hide('edit-subcore')"
          @reload-subcore="reload()"
        />
      </b-modal>
      <b-modal
        id="delete-subcore"
        :hide-footer="true"
        :hide-header="true"
        :centered="true"
      >
        <deleteCore
          confirm-btn="Yes"
          @cancel="$bvModal.hide('delete-subcore')"
          @confirm="deleteSubcore()"
        />
      </b-modal>
      <!-- Modal End -->

    </div>
  </div>
</template>
<style scoped>

</style>
<script>
import { mapGetters } from 'vuex'
import competency from "@/component/competency/get_competency.vue"
import behavioural from "@/component/competency/get_behavioural.vue"
import deleteCore from "@/pages/alert-modal.vue"
// import behavioural from "@/component/competency/get_behavioural_backup.vue"
import {
  BTabs,
  BTab,
  VBTooltip,
  BCard,
  BModal,
  BRow,
  BCol,
  BButton,
  BFormSelect,
  // BFormInput,
} from "bootstrap-vue"
import subcore from "@/component/competency/subcore.vue"
import SAJToast from '@/component/saj-toastification.vue'
// import behaviouralModal from "@/component/competency/behavioural_modal.vue"

export default {
  components: {

    BTabs,
    BTab,
    competency,
    behavioural,
    BCard,
    BModal,
    subcore,
    // behaviouralModal,
    BCol,
    BRow,
    BButton,
    BFormSelect,
    deleteCore,
    // BFormInput,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {

  },
  data() {
    return {
      coreList: [],
      subInfo: null,
      coreId: null,
      subcoreId: null,
      refreshTable: 0,
      subsidiaryList: [{ text: `${this.$i18n.t('Choose Subsidiary')}`, value: null, disabled: true }],
      search: false,
      selectedSub: null,
    }
  },
  computed: {
    isEnglish() {
      return this.$i18n.locale === 'en'
    },
    ...mapGetters(['userDetails']),
  },
  mounted() {
    this.getSubsidiaryList()
  },
  methods: {
    getCore(){
      // console.log(this.userDetails)
      this.$axios.get(`${this.$baseUrl}/competency_subcore/get_cores?subsidiary_id=${this.selectedSub}`).then(response => {
        const res = response.data.data

        this.coreList = res.map(x => ({
          core_bi: x.name_bi,
          core_bm: x.name_bm,
          id: x.id,
        }))
      })
    },
    reload(){
      // console.log('reload')
      this.refreshTable += 1
    },
    getSubsidiaryList(){
      this.$axios.get(`${this.$baseUrl}/subsidiary/getAll`).then(res => {
        const sub = res.data.data.subsidiaries

        sub.forEach(data => {
          this.subsidiaryList.push({
            text: data.subsidiary_name,
            value: data.id,
          })
        })
      })
    },
    deleteSubcore(){
      const data = new FormData()
      data.append('subcore_id', this.subcoreId)

      // data.forEach(x => {
      //   console.log('subcore id', x)
      // })

      this.$axios.post(`${this.$baseUrl}/competency_subcore/delete_subcores_content`, data)
        .then(() => {
          // this.$emit('reload-subcore')
          this.reload()
          this.$bvModal.hide('delete-subcore')

          this.$toast(
            {
              component: SAJToast,
              props: {
                title: `${this.$t('Successfully deleted')}.`,
                icon: 'CheckCircleIcon',
                variant: 'success',
                titleColor: '#000',
              },
            },
            {
              position: "top-right",
              type: 'info',
            },
          )
        })
    },
    clearSearch(){
      this.selectedSub = null
      this.search = false
    },
  },
}
</script>
