<template>
  <div class="">
    <div class="container">

      <div class="d-flex justify-content-end">
        <b-button
          variant="primary"
          class="saj-button mt-1"
          @click="$bvModal.show('new-behavioural')"
        >
          {{ $t('Add New Behavioural') }}
        </b-button>
      </div>

      <div>
        <b-table
          show-empty
          :items="behaviourList"
          :fields="fields"
          responsive=""
          bordered
          class="mt-1 mb-1 saj-subtitle"
        >
          <!-- A custom formatted column -->
          <template #head()="data">
            <span
              class="saj-title"
              style=""
            >{{ $t(data.label) }}</span>
          </template>
          <template #cell(index)="data">
            <div
              class="saj-text d-flex justify-content-center"
            >
              {{ data.index + 1 }}
            </div>
          </template>
          <template #empty="">
            <h4 style="text-align: center; font-style: italic">
              {{ $t('There are no records to show') }}
            </h4>
          </template>

          <template #cell(behaviour)="data">
            <!-- {{ data.item.id }} -->
            {{ isEnglish ? data.item.name_bi : data.item.name_bm }}
          </template>
          <!-- <template #cell(description)="data">
            {{ isEnglish ? data.item.description_bi : data.item.description_bm }}
          </template> -->
          <template #cell(action)="data">
            <div
              class="d-flex justify-content-center"
            >
              <!-- {{ data.item.employee_id }} -->
              <!-- {{ data.item.id }} -->
              <div @click="viewSubBehaviour(data.item.id)">
                <feather-icon
                  v-b-tooltip.hover.top="$t('View')"
                  icon="EyeIcon"
                  class="hoverIcon mr-1"
                  size="25"
                />
              </div>

              <feather-icon
                v-b-tooltip.hover.top="$t('Edit')"
                icon="EditIcon"
                size="25"
                style="color: green"
                class="mr-1"
                @click="$bvModal.show('edit-behavioural'), behavData = data.item"
              />
              <feather-icon
                v-b-tooltip.hover.top="$t('Delete')"
                icon="Trash2Icon"
                class="hoverIcon"
                style="color: red"
                size="25"
                @click="$bvModal.show('delete-behavioural'), behaviourID = data.item.id"
              />
            </div> </template></b-table>

      </div>
      <b-modal
        id="new-behavioural"
        :hide-header="true"
        :hide-footer="true"
        :centered="true"
        size="lg"
      >
        <behaviouralModal
          :is-update="false"
          :behaviour-data="{}"
          @close="$bvModal.hide('new-behavioural')"
          @reload-behaviour="getBehaviour()"
        />
      </b-modal>
      <b-modal
        id="edit-behavioural"
        :hide-footer="true"
        :hide-header="true"
        :centered="true"
        size="lg"
      >
        <behaviouralModal
          :behaviour-data="behavData"
          @close="$bvModal.hide('edit-behavioural')"
          @reload-behaviour="getBehaviour()"
        />
      </b-modal>
      <b-modal
        id="delete-behavioural"
        :hide-footer="true"
        :hide-header="true"
        :centered="true"
      >
        <deleteBehav
          confirm-btn="Yes"
          @cancel="$bvModal.hide('delete-behavioural')"
          @confirm="deleteBehavioural()"
        />
      </b-modal>
    </div>
  </div>
</template>
<style scoped>

</style>
<script>
import {
  BTable,
  VBTooltip,
  BButton,
} from "bootstrap-vue"
import deleteBehav from "@/pages/alert-modal.vue"
import behaviouralModal from "@/component/competency/behaviour_modal.vue"
import SAJToast from '@/component/saj-toastification.vue'

export default {
  components: {
    BTable,
    BButton,
    behaviouralModal,
    deleteBehav,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {

    subsidiaryId: {
      type: Number,
      default: null,
    },

  },
  data() {
    return {
      subcoreData: null,
      competencyList: [
        {
          subcore: 'Subcore 1',
          description: 'Subcore 1 desc',
        },
        {
          subcore: 'Subcore 2',
          description: 'Subcore 2 desc',
        },
      ],
      fields: [
        {
          key: 'index', label: 'No.', thClass: 'text-center',
        },
        {
          key: 'behaviour', label: 'Behavioural', thClass: 'text-center', tdClass: 'text-center',
        },
        // { key: 'description', label: 'Description', thClass: 'text-center' },
        { key: 'action', label: 'Action', thClass: 'text-center' },
      ],
      behaviourList: [],
      behavData: null,
      behaviourID: null,
    }
  },
  computed: {
    isEnglish() {
      return this.$i18n.locale === 'en'
    },
  },
  mounted() {
    this.getBehaviour()
  },
  methods: {
    getBehaviour(){
      this.$axios.get(`${this.$baseUrl}/competency_behaviour/subsidiary_get_behaviours?subsidiary_id=${this.subsidiaryId}`).then(response => {
        this.behaviour = response.data.data
        this.behaviourList = this.behaviour.map(x => ({
          id: x.id,
          name_bm: x.name,
          name_bi: x.name_bi,
        }))
      })
    },
    deleteBehavioural(){
      const data = new FormData()
      data.append('behaviour_id', this.behaviourID)

      this.$axios.post(`${this.$baseUrl}/competency_behaviour/delete_behaviour_permanently`, data).then(() => {
        this.getBehaviour()
        this.$bvModal.hide('delete-behavioural')

        this.$toast(
          {
            component: SAJToast,
            props: {
              title: `${this.$t('Successfully deleted')}.`,
              icon: 'CheckCircleIcon',
              variant: 'success',
              titleColor: '#000',
            },
          },
          {
            position: "top-right",
            type: 'info',
          },
        )
      })
    },
    viewSubBehaviour(id) {
      this.$router.push({ name: 'View-Competency', params: { id } })
    },
  },
}
</script>
